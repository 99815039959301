import { useState, useEffect } from 'react'

const useIsBlacklisted = (skipCheck: boolean) => {
  const [clientIP, setClientIP] = useState('')
  const whitelistedIPsAretaeio = [
    '46.199.93.146',
    '46.199.93.147',
    '46.199.93.148',
    '46.199.93.149',
    '46.199.93.150',
    '82.102.72.134',
    '82.102.72.131',
    '82.102.72.136',
    '82.102.72.137',
    '82.102.72.132',
    '46.28.177.145',
    '73.63.176.197',
    '45.248.162.226',
    '117.240.68.130',
    '183.83.140.100',
    '95.90.211.75'
  ]

  const aretaeioDomains = [
    'aretaeio.mayamd.ai',
    'aretaeio-staging.mayamd.ai',
    'aretaeio-test.mayamd.ai',
    'mayamd.aretaeio.com',
    'localhost'
  ]

  useEffect(() => {
    const getIP = async () => {
      if (skipCheck) {
        setClientIP('skipped')
      } else {
        const ipRequest = await fetch(`https://api.ipify.org?format=json`)
        const IP = await ipRequest.json()
        if (IP?.ip) {
          setClientIP(IP.ip)
        } else {
          setClientIP('error')
        }
      }
    }

    getIP()
  }, [skipCheck])

  const isBlacklisted =
    !skipCheck &&
    clientIP &&
    aretaeioDomains.includes(window.location.hostname) &&
    !whitelistedIPsAretaeio.includes(clientIP)

  return isBlacklisted
}

export default useIsBlacklisted
