export const styles = {
  card: {
    label: 'Authentication-card',
    width: ['100%', '100%', '40%'],
    minWidth: 300,
    bg: 'backgroundPrimary',
    padding: 10,
    border: 'default',
    borderRadius: 'card',
    flexDirection: 'column'
  },
  title: {
    label: 'Authentication-title',
    fontSize: 5,
    textAlign: 'center',
    color: 'text',
    mb: 12
  },
  phone: {
    label: 'Authentication-title',
    fontSize: 5,
    textAlign: 'center',
    color: 'text',
    mb: 2
  },
  subtitle: {
    label: 'Authentication-subtitle',
    fontSize: 2,
    textAlign: 'center',
    color: 'text',
    mb: 12
  },
  inputContainer: {
    label: 'Authentication-inputContainer',
    bg: 'inputBackground',
    boxSizing: 'border-box',
    fontSize: '16px',
    p: '0.5rem',
    mb: 12,
    borderRadius: 'input',
    border: 'default',
    display: 'flex',
    '& > div': {
      width: '100%'
    },
    '&:focus-within': {
      border: 'focused'
    }
  },
  textError: {
    label: 'Authentication-textError',
    fontSize: 2,
    textAlign: 'center',
    color: 'error'
  },
  textButton: {
    label: 'Authentication-textButton',
    fontSize: 2,
    cursor: 'pointer',
    textAlign: 'center',
    color: 'primary',
    mb: 12
  },
  codeInputContainer: {
    label: 'Authentication-codeInput',
    display: 'flex',
    flexOrientation: 'row',
    justifyContent: 'space-around',
    mb: 12
  },
  codeInputSolo: {
    label: 'Authentication-codeInputSolo',
    fontSize: 3,
    height: 40,
    width: 48,
    textAlign: 'center',
    py: 1,
    px: 4,
    ':focus': {
      border: '1px solid #000000'
    }
  }
}
