import unescape from 'lodash.unescape'
import React, {
  createElement,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Flex, Heading, Image, Link, Text } from 'rebass'
import InfoIcon from './informationIcon.png'
// @ts-ignore
import { Label } from '@rebass/forms'
import marksy from 'marksy'
import { UneeqContext, useTranscript, useUneeqState } from 'uneeq-react-core'
import { useDomainConfigContext } from '../../app/hooks/useDomainConfigContext'
import { useSendSocketMessage } from '../../app/hooks/useSendSocketMessage'
import { useTvContext } from '../../app/hooks/useTvContext'
import { completeTodo, sendWellnessPatientNote } from '../../TvApp/api'
import { downloadBase64 } from '../../utils'
import MayaBackButton from '../MayaBackButton/MayaBackButton'
import MayaHomeButton from '../MayaHomeButton/MayaHomeButton'
import { MayaRequestAppointmentButton } from '../MayaRequestAppointmentButton'
import RestartSession from '../RestartSession/RestartSession'
import { ReactComponent as ChevronDown } from './down-chevron.svg'
import styles from './styles'

const compile = (sendMessage: (request: any) => void, dispatch: any) =>
  marksy({
    elements: {
      a: ({ href, children, ...otherProps }: any) => {
        if (href.includes('maya-page://')) {
          const prefixRemoved = href.split('maya-page://')[1]
          const [pageId, params] = prefixRemoved.split('/?')

          const formattedParams = params
            ? params
                .replace(/&amp;/g, '&')
                .split('&')
                .map((keyval: string) => {
                  const [key, value] = keyval.split('=')
                  return { [key]: decodeURIComponent(value) }
                })
                .reduce(
                  (
                    obj: Record<string, string>,
                    curr: Record<string, string>
                  ) => ({ ...obj, ...curr }),
                  {}
                )
            : {}
          return (
            <Text
              as="a"
              className="focusable"
              sx={
                children.some((el: any) => el?.props?.src === '/info.png')
                  ? {}
                  : styles.mayaPageLink
              }
              onClick={() => {
                dispatch({
                  type: 'mayaMessage',
                  payload: { type: 'storeInfoPage' }
                })
                sendMessage({
                  type: 'infoPage',
                  pageId: pageId,
                  params: formattedParams
                })
              }}
            >
              {children}
            </Text>
          )
        } else {
          return (
            <Link
              href={unescape(href)}
              {...otherProps}
              target="_blank"
              rel="noopener noreferrer"
              sx={styles.anchor}
            >
              {children}
            </Link>
          )
        }
      },
      h1: ({ children }: any) => (
        <Heading as="h1" sx={styles.heading}>
          {children}
        </Heading>
      ),
      h2: ({ children }: any) => (
        <Heading as="h2" sx={styles.headingTwo}>
          {children}
        </Heading>
      ),
      h3: ({ children }: any) => (
        <Heading as="h3" sx={styles.headingThree}>
          {children}
        </Heading>
      ),
      h4: ({ children }: any) => (
        <Heading as="h4" sx={styles.headingFour}>
          {children}
        </Heading>
      ),
      h5: ({ children }: any) => (
        <Heading as="h5" sx={styles.headingFive}>
          {children}
        </Heading>
      ),
      h6: ({ children }: any) => (
        <Heading as="h6" sx={styles.headingSix}>
          {children}
        </Heading>
      ),
      hr: ({ children }: any) => <hr style={styles.hr}>{children}</hr>,
      p: ({ children }: any) => <Text sx={styles.paragraph}>{children}</Text>,
      img({ src, alt, title }: any) {
        if (alt.includes('progressbar')) {
          const color = alt.split('-')[1]
          const percentage = src.replace(/\D+/g, '')
          return (
            <Flex sx={styles.loading.barContainer}>
              <Flex sx={styles.loading.barInnerContainer}>
                <Flex
                  sx={{
                    ...styles.loading.bar,
                    backgroundColor: color ? color : 'primary',
                    width: `${percentage}%`
                  }}
                />
              </Flex>
            </Flex>
          )
        } else if (src === '/info.png') {
          return (
            <Image
              sx={{
                cursor: 'pointer',
                width: '18px',
                height: '18px',
                verticalAlign: 'text-top'
              }}
              src={InfoIcon}
            />
          )
        } else {
          return <Image src={src} alt={alt} />
        }
      },
      del: ({ children }: any) => {
        return (
          <Box
            sx={{
              '& .collapsible:checked ~ .content': {
                display: 'block'
              },
              '& .content': {
                display: 'none'
              }
            }}
          >
            <Label>
              <input
                type="checkbox"
                className="collapsible"
                style={{ display: 'none' }}
              />
              <Box variant="whiteInverted" sx={styles.showMoreButton}>
                Show More
              </Box>
              <Box className="content" sx={{ display: 'none' }}>
                {children}
              </Box>
            </Label>
          </Box>
        )
      }
    },

    createElement
  })

type DownloadPdfButton = { label?: string; pdf?: string; name?: string }
const DownloadPdfButton = ({ label, pdf, name }: DownloadPdfButton) => {
  const { t } = useTranslation()
  if (!pdf) return null

  const buttonText = label ? label : t('Question.downloadPdfButton')

  return (
    <Button
      onClick={() => downloadBase64(pdf, 'application/pdf')}
      variant="information"
    >
      {buttonText}
    </Button>
  )
}

interface ActionButtonProps {
  text: string
  action: string
  link?: string
  keepInfoOpen: boolean
  important: boolean
}

interface MayaInformationProps {
  hideDownloadNotes?: boolean
  patientSelectedId?: string
  preAuthToken?: string
}

const MayaInformation = ({
  hideDownloadNotes,
  patientSelectedId,
  preAuthToken
}: MayaInformationProps) => {
  const { mayaInformation, hideInformation } = useUneeqState()
  const sendMessage = useSendSocketMessage()
  const { dispatch, showDH } = useContext(UneeqContext)
  const [showArrow, setShowArrow] = useState(false)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const {
    wellnessCheckInTodoId,
    session,
    setTodoList,
    todoList
  } = useTvContext()
  const { transcript } = useTranscript()
  const { setWellnessCheckInTodoId } = useTvContext()

  useEffect(() => {
    if (
      mayaInformation?.pageId === 'thanks' ||
      mayaInformation?.pageId === 'inference'
    ) {
      const token = localStorage.getItem('lg_api_token')
      const user = JSON.parse(localStorage.getItem('lg_api_user') || '')
      const patientId = user?.patient?._id

      if (token) {
        try {
          // if there's a patient note PDF, send it to patient portal
          if (mayaInformation?.pdf) {
            sendWellnessPatientNote(mayaInformation.pdf, session, patientId)
          }
          if (wellnessCheckInTodoId) {
            console.log('about to mark todo as done', wellnessCheckInTodoId)
            // call API to mark to-do as done
            completeTodo(wellnessCheckInTodoId, token)

            // remove ID from context so we don't show Wellness Check Button again
            setWellnessCheckInTodoId('')

            // mark todo as done in our local state
            setTodoList(
              todoList &&
                todoList.map(todo => {
                  if (todo._id === wellnessCheckInTodoId) {
                    return {
                      ...todo,
                      status: 'completed'
                    }
                  }
                  return todo
                })
            )
          }
        } catch (e) {}
      }
    }
  }, [
    mayaInformation,
    session,
    setTodoList,
    setWellnessCheckInTodoId,
    todoList,
    transcript,
    wellnessCheckInTodoId
  ])
  const {
    state: { hideInfoActions }
  } = useDomainConfigContext()

  useEffect(() => {
    setShowArrow(false)
    if (scrollContainerRef.current) {
      const containerRef = scrollContainerRef.current
      if (containerRef?.scrollHeight > containerRef?.offsetHeight) {
        setShowArrow(true)
      } else {
        setShowArrow(false)
      }
      const hideArrowFn = () => {
        setShowArrow(false)
      }
      containerRef.addEventListener('scroll', hideArrowFn)

      return () => containerRef.removeEventListener('scroll', hideArrowFn)
    }
  }, [mayaInformation])

  if (!mayaInformation?.markdown || hideInformation) return null

  const clearInformation = () => {
    clearInfoPage()
    sendMessage({ type: 'infoClosed', pageId: mayaInformation.pageId })
  }

  const sendAction = (action: string, keepInfoOpen: boolean) => {
    if (!keepInfoOpen) {
      clearInfoPage()
    }
    sendMessage({ type: 'button', action })
  }

  const clearInfoPage = () =>
    dispatch({ type: 'mayaMessage', payload: { type: 'clearInfoPage' } })

  const buttons = () => {
    if (mayaInformation.buttons) {
      const importantButtons = mayaInformation.buttons.filter(
        (btn: ActionButtonProps) => btn.important
      )
      const restOfButtons = mayaInformation.buttons.filter(
        (btn: ActionButtonProps) => !btn.important
      )

      return importantButtons
        .concat(restOfButtons)
        .map(
          ({
            text,
            action,
            keepInfoOpen,
            link,
            important
          }: ActionButtonProps) => {
            const handleClick = () => {
              if (link) {
                window.open(link, action)
                return sendAction(action, true)
              }

              sendAction(action, keepInfoOpen)
            }

            return (
              <Button
                type="submit"
                key={action}
                onClick={handleClick}
                variant={important ? 'informationImportant' : 'information'}
              >
                {text}
              </Button>
            )
          }
        )
    }

    return null
  }

  const compiled = compile(sendMessage, dispatch)(mayaInformation.markdown)
  console.log('mayaInformation', mayaInformation)
  return (
    <Flex
      id="information-container"
      variant="questionContainer"
      sx={{
        ...styles.informationContainer,
        maxHeight: showDH
          ? [
              '50vh',
              '50vh',
              '50vh',
              '50vh',
              'calc(100% - 80px)',
              'calc(100% - 80px)'
            ]
          : [
              '100vh',
              '100vh',
              '100vh',
              '100vh',
              'calc(100% - 80px)',
              'calc(100% - 80px)'
            ]
      }}
    >
      <Flex ref={scrollContainerRef} sx={styles.content}>
        {compiled.tree}
      </Flex>
      <Flex sx={styles.column}>
        {showArrow && (
          // eslint-disable-next-line react/jsx-no-undef
          <Box sx={styles.arrowDownContainer}>
            <Text variant="unstyled" sx={styles.arrowDown}>
              <ChevronDown />
            </Text>
          </Box>
        )}
        <Flex sx={styles.divider} />
        <Flex id="info-actions" variant="informationButtonsContainer">
          {patientSelectedId && mayaInformation.buttonText && (
            <MayaRequestAppointmentButton
              patientSelectedId={patientSelectedId}
              preAuthToken={preAuthToken}
              variant="informationImportant"
            />
          )}
          {buttons()}
          {!hideDownloadNotes && (
            <DownloadPdfButton
              pdf={mayaInformation.pdf?.base64}
              name={mayaInformation.pdf?.name}
              label={mayaInformation.pdf?.label}
            />
          )}
          {mayaInformation.buttonText ? (
            <Button
              type="submit"
              variant="information"
              onClick={clearInformation}
            >
              {mayaInformation.buttonText ? mayaInformation.buttonText : 'Okay'}
            </Button>
          ) : null}
        </Flex>
        {!hideInfoActions && (
          <Flex sx={styles.actions}>
            {!mayaInformation.hideBack && (
              <MayaBackButton
                onClick={clearInfoPage}
                sx={{ display: 'flex', alignSelf: 'center', mr: 2 }}
              />
            )}
            <Flex sx={styles.sessionActions}>
              {mayaInformation.showRestart && (
                <RestartSession
                  onClick={() => dispatch({ type: 'mayaOpenConfirmRestart' })}
                />
              )}
              <MayaHomeButton
                onClick={() => dispatch({ type: 'mayaOpenConfirmLeave' })}
              />
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default MayaInformation
